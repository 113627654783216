<template>
  <div>
    <img class="tui-img" v-if="imgList.img" :src="imgList.img" alt="" />
    <div class="main">
      <!-- 搜索 -->
      <kind-choose-view :showSchool="true" :showKc="false" :showZy="false" :showLevel="false" :type="null" :ifImg="false"
        @isOk="isOk" />
      <div class="bigBox">
        <div class="teacherWrap">
          <div class="teacherItem" v-for="(item, index) in teacherList" :key="index" @click="goDetail(item)">
            <el-image class="itemImg" :src="item.terImg" fit="cover"></el-image>
            <div class="itemContentWrap">
              <div class="nameWrap">
                <div class="teacherName"> {{ item.isRecommend ? item.userName : item.nickname }}</div>
                <div class="course">{{ item.jobTitle }}</div>
              </div>
              <div class="dottedLine"></div>
              <div class="introduce" v-html="ImgSizeLimit(item.profile)"></div>
              <div class="itemBtn">查看详情 ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination class="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="page.pageNum" :page-sizes="[9, 18, 27, 36]" :page-size="page.pageSize"
      layout="total, sizes, prev, pager, next, jumper" :total="page.total"></el-pagination>
  </div>
</template>

<script>
import kindChooseView from "@/components/kindChooseView.vue";
import { getSurfaceList } from "@/api/home";
import { TeacherClient } from "@/api/teacherClient.js";
import { getDomain } from "@/api/cookies";
let teacherClient = new TeacherClient();
export default {
  components: {
    kindChooseView,
  },
  data() {
    return {
      teacherList: [],
      page: {
        pageSize: 9,
        pageNum: 1,
        total: 0,
      },
      teacherList: [],
      searchVal: {},
      imgList: {},
      domain: null
    };
  },
  created() {
    this.domain = getDomain();
    this.getTeacherList();
    getSurfaceList(5, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0 && res.msg.length > 0) {
        this.imgList = res.msg[0];
      } else {
        this.imgList = {};
      }
    });
  },
  methods: {
    /* 点击查看详情 */
    goDetail(item) {
      this.$router.push({
        path: "/faculty/teacherDetail",
        query: {
          id: item.id,
        },
      });
    },
    isOk(val) {
      this.searchVal = val;
      this.getTeacherList();
    },
    /* 师资力量列表 */
    getTeacherList() {
      let params = {
        ...this.page,
        pKindId: this.searchVal.school,
        kindId: this.searchVal.kind,
      };
      delete params.total;
      teacherClient.selectPCPageList(params).then((res) => {
        this.teacherList = res.rows;
        this.page.total = res.total;
      });
    },
    /* 每页条数 */
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.pageNum = 1;
      this.getTeacherList();
    },
    /* 当前页码 */
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getTeacherList();
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 1220px;
  margin: 0 auto;
  margin-top: 24px;
}

/deep/ .box-div {
  border-radius: 8px 8px 8px 8px;
}

/deep/ .zychoice {
  // height: 82px;
  // line-height: 82px;
  border-radius: 8px 8px 8px 8px;
  padding-left: 32px;
}

.bannerWrap {
  width: 100%;
  height: 360px;
}

.bigBox {
  width: 1220px;
  margin: auto;
  margin-top: 50px;

  .teacherWrap {
    display: flex;
    flex-wrap: wrap;

    .teacherItem {
      width: 390px;
      height: 280px;
      border-radius: 8px;
      background-color: #ffffff;
      padding: 24px;
      margin-right: 15px;
      margin-bottom: 24px;
      display: flex;

      .itemImg {
        width: 160px;
        height: 240px;
        border-radius: 8px;
        margin-right: 20px;
      }

      .itemContentWrap {
        padding: 16px 20px 0 0;

        .nameWrap {
          width: 154px;
          height: 62px;

          .teacherName {
            color: #333333;
            font-size: 20px;
            margin-bottom: 16px;
          }

          .course {
            font-size: 14px;
            width: 162px;
            color: #999999;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
          }
        }

        .introduce {
          font-size: 14px;
          color: #666666;
          line-height: 26px;
          margin-bottom: 19px;
          width: 162px;
          height: 78px;
          overflow: hidden;
        }

        .dottedLine {
          width: 162px;
          height: 1px;
          border: 1px #cccccc dashed;
          margin: 16px 0 12px 0;
        }

        .itemBtn {
          color: #d69d66;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
  }
}

.tui-img {
  width: 100%;
  /* height: 360px; */
}

/deep/ .el-pagination .active {
  background: #1061ff !important;
}

/deep/ .on {
  background: #ecf2ff !important;
  color: #1061ff !important;
}
</style>
